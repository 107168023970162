import {
    HttpContextToken,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TenantService } from "app/tenant/module/tenant.service";
import { Observable, first, switchMap } from "rxjs";

export const SKIP_INJECTED_TENANT_ID = new HttpContextToken<boolean>(() => false);

@Injectable()
export class TenantIdInterceptor implements HttpInterceptor {
    public constructor(private tenantService: TenantService) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.context.get(SKIP_INJECTED_TENANT_ID) || !this.tenantService.isOnTenantedRoute) {
            return next.handle(req);
        }

        return this.tenantService.currentTenantId$.pipe(
            first(),
            switchMap((tenantId) => {
                if (!tenantId) {
                    return next.handle(req);
                }

                const authorisedRequest = req.clone({
                    headers: req.headers.set("X-Tenant-Id", tenantId),
                });
                return next.handle(authorisedRequest);
            }),
        );
    }
}
